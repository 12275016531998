import StorefrontIcon from "components/Icons/storefront.svg";

const DefaultPickupLabel = () => {
  return (
    <div className="flex items-center bg-leafly-white rounded px-sm text-xs min-h-6">
      <StorefrontIcon width="12" height="12" />
      <span className="ml-xs font-bold my-[2px]">Pickup</span>
    </div>
  );
};

export default DefaultPickupLabel;
