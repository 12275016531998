import React from "react";

type Props = {
  label: string;
};

export default function CardRibbon({ label }: Props) {
  return (
    <div
      className="absolute top-0 left-0 card-ribbon origin-top-left z-10 px-8 bg-blue-grey font-bold text-white text-xs"
      // Note: Using tailwind classes to perform these transformations
      // will always apply the translate before the rotate, which will not
      // produce the effect that we need. Instead, we have to use the style
      // property to implement the transform.
      style={{ transform: "rotate(-45deg) translate(-50%, 20px)" }}
    >
      {label}
    </div>
  );
}
